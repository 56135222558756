import { LanguageCode } from "~/types"

export function getLocale(languageCode: LanguageCode): string {
    switch (languageCode) {
        case LanguageCode.EnglishUsa:
            return "en"
        case LanguageCode.EnglishUk:
            return "en"
        case LanguageCode.EnglishCanada:
            return "en"
        case LanguageCode.FrenchCanada:
            return "fr"
        case LanguageCode.FrenchFrance:
            return "fr"
        case LanguageCode.GermanGermany:
            return "de"
        case LanguageCode.SpanishUsa:
            return "es"
        default:
            return "en"
    }
}

export default {
    // This is the list of languages your application supports
    supportedLngs: ["de", "en", "es", "fr"],
    // This is the language you want to use in case
    // if the user language is not in the supportedLngs
    fallbackLng: "en",
    // The default namespace of i18next is "translation", but you can customize it here
    defaultNS: "common",
    // Disabling suspense is recommended
    react: { useSuspense: false },
};